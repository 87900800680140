<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        dense
        card
      >
        <v-menu
          v-model="menu"
          close-on-content-click
          transition="scale-transition"
          full-width
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="monthOf"
            prepend-icon="event"
            readonly
          />
          <v-date-picker
            v-model="monthOf"
            :max="maxDate"
            :allowed-dates="allowedMonths"
            min="2018-01"
            type="month"
            year-icon="date_range"
          />
        </v-menu>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-xs>
          <v-layout
            row
            wrap
          >
            <template v-for="field in self">
              <v-flex
                :key="field.name"
                xs4
              >
                <span class="primary--text">{{ field.name }}</span>
              </v-flex>
              <v-flex
                :key="field.name + 'value'"
                xs2
              >
                {{ field.value }}
              </v-flex>
            </template>
          </v-layout>
        </v-container>
      </v-card-text>
      <template v-if="areaDealers.length">
        <v-divider />
        <v-card-title>Area Dealer Performance</v-card-title>
        <v-data-table
          :headers="headersAd"
          :items="areaDealers"
          :loading="loading"
          :pagination.sync="paginationAd"
          class="px-1 pb-1"
          item-key="dealer"
        >
          <template
            slot="headerCell"
            slot-scope="props"
          >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <span v-on="on">
                  {{ props.header.text }}
                </span>
              </template>
              <span>
                {{ props.header.description }}
              </span>
            </v-tooltip>
          </template>
          <template
            slot="items"
            slot-scope="props"
          >
            <tr @click="props.expanded = !props.expanded">
              <td class="text-xs-left">
                {{ props.item.dealer }}
              </td>
              <td class="text-xs-center">
                {{ props.item.qualifiedTiers }}
              </td>
              <td class="text-xs-center">
                {{ props.item.prepaidGroupRegistration }}
              </td>
              <td class="text-xs-center">
                {{ props.item.prepaidGroupActivation }}
              </td>
              <td class="text-xs-center">
                {{ props.item.blackGroupActivation }}
              </td>
              <td class="text-xs-center">
                {{ props.item.dealerGroupActivation }}
              </td>
              <td class="text-xs-center">
                {{ props.item.erechargeSales }}
              </td>
              <td class="text-xs-center">
                {{ props.item.dealerAppointment }}
              </td>
            </tr>
          </template>
          <template #footer>
            <tr>
              <td class="text-xs-left">
&nbsp;
              </td>
              <td class="text-xs-center primary--text">
                {{ adQualifiedTiers }}
              </td>
              <td class="text-xs-center primary--text">
                {{ adPrepaidGroupRegistration }}
              </td>
              <td class="text-xs-center primary--text">
                {{ adPrepaidGroupActivation }}
              </td>
              <td class="text-xs-center primary--text">
                {{ adBlackGroupActivation }}
              </td>
              <td class="text-xs-center primary--text">
                {{ adDealerGroupActivation }}
              </td>
              <td class="text-xs-center primary--text">
                {{ Number(adErechargeSales).toFixed(2) }}
              </td>
              <td class="text-xs-center primary--text">
                {{ adDealerAppointment }}
              </td>
            </tr>
          </template>
          <template slot="no-data">
            {{ noDataText }}
          </template>
          <template slot="no-result">
            <v-alert
              :value="true"
              color="error"
              icon="warning"
            >
              {{ noResultText }}
            </v-alert>
          </template>
          <template
            slot="expand"
            slot-scope="props"
          >
            <v-card class="elevation-1 mx-2 mb-2 inner">
              <v-card-text>
                <v-container
                  fluid
                  grid-list-xs
                >
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex xs3>
                      <span class="primary--text">Name</span>
                    </v-flex>
                    <v-flex xs9>
                      {{ props.item.name }}
                    </v-flex>
                    <v-flex xs3>
                      <span class="primary--text">Msisdn</span>
                    </v-flex>
                    <v-flex xs9>
                      {{ props.item.msisdn }}
                    </v-flex>
                    <v-flex xs3>
                      <span class="primary--text">Dealership date</span>
                    </v-flex>
                    <v-flex xs9>
                      {{ props.item.dealershipDate }}
                    </v-flex>
                    <v-flex xs3>
                      <span class="primary--text">Status</span>
                    </v-flex>
                    <v-flex xs9>
                      {{ props.item.status }}
                    </v-flex>
                    <v-flex xs3>
                      <span class="primary--text">Plan</span>
                    </v-flex>
                    <v-flex xs9>
                      {{ props.item.plan }}
                    </v-flex>
                    <v-flex xs3>
                      <span class="primary--text">Tier-1 dealers</span>
                    </v-flex>
                    <v-flex xs9>
                      {{ props.item.dealerCount }}
                    </v-flex>
                    <v-flex xs3>
                      <span class="primary--text">Tier-1 active subscribers</span>
                    </v-flex>
                    <v-flex xs9>
                      {{ props.item.subscriberCount }}
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </template>
        </v-data-table>
      </template>
      <v-divider />
    </v-card>
    <v-card>
      <v-card-title>Dealer Performance</v-card-title>
      <v-toolbar
        card
        class="transparent"
      >
        <v-select
          v-if="areaDealers.length"
          v-model="currentAd"
          :items="adList"
          label="Select AD"
        />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          clearable
        />
        <v-spacer />
        <v-tooltip
          v-if="dealers.length"
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              absolute
              fab
              dark
              small
              right
              color="green"
              v-on="on"
              @click="exportDealerPerformance"
            >
              <v-icon dark>
                mdi-microsoft-excel
              </v-icon>
            </v-btn>
          </template>
          <span>
            Export Report
          </span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="filteredDealers"
        :loading="loading"
        :pagination.sync="pagination"
        :rows-per-page-items="[5,10,25,50,100]"
        :search="search"
        class="px-1 pb-1"
        item-key="dealer"
      >
        <template
          slot="headerCell"
          slot-scope="props"
        >
          <v-tooltip bottom>
            <template #activator="{ on }">
              <span v-on="on">
                {{ props.header.text }}
              </span>
            </template>
            <span>
              {{ props.header.description }}
            </span>
          </v-tooltip>
        </template>
        <template
          slot="items"
          slot-scope="props"
        >
          <tr @click="props.expanded = !props.expanded">
            <td class="text-xs-left">
              {{ props.item.dealer }}
            </td>
            <td class="text-xs-center">
              {{ props.item.tier }}
            </td>
            <td class="text-xs-center">
              {{ props.item.prepaidRegistration }}
            </td>
            <td class="text-xs-center">
              {{ props.item.prepaidActivation }}
            </td>
            <td class="text-xs-center">
              {{ props.item.blackActivation }}
            </td>
            <td class="text-xs-center">
              {{ props.item.groupActivation }}
            </td>
            <td class="text-xs-center">
              {{ props.item.erechargeSales }}
            </td>
            <td class="text-xs-center">
              {{ props.item.dealerAppointment }}
            </td>
            <td
              v-if="props.item.superDealer"
              class="text-xs-left"
            >
              <span class="success--text">Super Dealer</span>
            </td>
            <td
              v-else
              class="text-xs-left"
            >
              <span>{{ props.item.dealerRank }}</span>
            </td>
            <td class="text-xs-center">
              {{ props.item.kpiCount }}
            </td>
            <td
              v-if="props.item.lead"
              class="text-xs-center"
            >
              <v-icon
                v-for="i in props.item.lead"
                :key="i"
                color="amber"
              >
                mdi-star
              </v-icon>
            </td>
            <td
              v-else
              class="text-xs-center"
            >
              <v-icon>mdi-star-off</v-icon>
            </td>
          </tr>
        </template>
        <template #footer>
          <tr>
            <td class="text-xs-left">
&nbsp;
            </td>
            <td class="text-xs-center">
&nbsp;
            </td>
            <td class="text-xs-center primary--text">
              {{ totalPrepaidRegistration }}
            </td>
            <td class="text-xs-center primary--text">
              {{ totalPrepaidActivation }}
            </td>
            <td class="text-xs-center primary--text">
              {{ totalBlackActivation }}
            </td>
            <td class="text-xs-center primary--text">
              {{ totalGroupActivation }}
            </td>
            <td class="text-xs-center primary--text">
              {{ Number(totalErechargeSales).toFixed(2) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ totalDealerAppointment }}
            </td>
            <td class="text-xs-left">
&nbsp;
            </td>
            <td class="text-xs-left">
&nbsp;
            </td>
            <td class="text-xs-left">
&nbsp;
            </td>
          </tr>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
        <template
          slot="expand"
          slot-scope="props"
        >
          <v-card class="elevation-1 mx-2 mb-2 inner">
            <v-card-text>
              <v-container
                fluid
                grid-list-xs
              >
                <v-layout
                  row
                  wrap
                >
                  <v-flex xs3>
                    <span class="primary--text">Name</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.name }}
                  </v-flex>
                  <v-flex xs3>
                    <span class="primary--text">Msisdn</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.msisdn }}
                  </v-flex>
                  <v-flex xs3>
                    <span class="primary--text">Dealership date</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.dealershipDate }}
                  </v-flex>
                  <v-flex xs3>
                    <span class="primary--text">Status</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.status }}
                  </v-flex>
                  <v-flex xs3>
                    <span class="primary--text">Plan</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.plan }}
                  </v-flex>
                  <v-flex xs3>
                    <span class="primary--text">Tier-1 dealers</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.dealerCount }}
                  </v-flex>
                  <v-flex xs3>
                    <span class="primary--text">Tier-1 active subscribers</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.subscriberCount }}
                  </v-flex>
                  <v-flex xs3>
                    <span class="primary--text">Introducer</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.intro }}
                  </v-flex>
                  <v-flex xs3>
                    <span class="primary--text">Area Dealer / Master Dealer</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.leadDealer }}
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </template>
      </v-data-table>
    </v-card>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'
import security from '@/security'
import zipcelx from 'zipcelx'

const reportTitle = 'Performance'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      currentAd: null,
      adQualifiedTiers: 0,
      adPrepaidGroupRegistration: 0,
      adPrepaidGroupActivation: 0,
      adBlackGroupActivation: 0,
      adDealerGroupActivation: 0,
      adErechargeSales: 0,
      adDealerAppointment: 0,
      totalPrepaidRegistration: 0,
      totalPrepaidActivation: 0,
      totalBlackActivation: 0,
      totalGroupActivation: 0,
      totalErechargeSales: 0,
      totalDealerAppointment: 0,
      areaDealers: [],
      breadcrumbs: [
        {
          text: 'Subscriber Group', disabled: false, to: '/subscriber_group',
        },
        {
          text: reportTitle, disabled: true,
        },
      ],
      dealerPerformanceHeader:
        [
          {
            value: 'MSISDN',
            type: 'string',
          },
          {
            value: 'Name',
            type: 'string',
          },
          {
            value: 'Subscription Date',
            type: 'string',
          },
          {
            value: 'Plan',
            type: 'string',
          },
          {
            value: 'Dealer ID',
            type: 'string',
          },
          {
            value: 'Dealership Date',
            type: 'string',
          },
          {
            value: 'Rank',
            type: 'string',
          },
          {
            value: 'Tier',
            type: 'string',
          },
          {
            value: '1st-tier Dealer',
            type: 'string',
          },
          {
            value: '1st-tier Subscriber',
            type: 'string',
          },
          {
            value: 'Prepaid Registration',
            type: 'string',
          },
          {
            value: 'Prepaid Activation',
            type: 'string',
          },
          {
            value: 'Black Activation',
            type: 'string',
          },
          {
            value: '4-tier Group Activation',
            type: 'string',
          },
          {
            value: 'Erecharge Sales',
            type: 'string',
          },
          {
            value: 'Dealer Recruitment',
            type: 'string',
          },
          {
            value: 'KPI Count',
            type: 'string',
          },
          {
            value: 'LEAD',
            type: 'string',
          },
          {
            value: 'Introducer',
            type: 'string',
          },
          {
            value: 'leadDealer',
            type: 'string',
          },
        ],
      headers: [
        {
          align: 'left',
          sortable: true,
          text: 'Dealer',
          description: 'Dealer Id',
          value: 'dealer',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Tier',
          description: 'Tier',
          value: 'tier',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Prepaid Reg',
          description: 'Prepaid registration',
          value: 'prepaidRegistration',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Prepaid Act',
          description: 'Prepaid activations',
          value: 'prepaidActivation',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Black Act',
          description: 'Black activations',
          value: 'blackActivation',
        },
        {
          align: 'center',
          sortable: true,
          text: '4-tier Grp Act',
          description: '4-tier group activations',
          value: 'groupActivation',
        },
        {
          align: 'center',
          sortable: true,
          text: 'E-recharge Sales',
          description: 'E-recharge sales',
          value: 'erechargeSales',
        },
        {
          align: 'center',
          sortable: true,
          text: 'New Dealer',
          description: 'New dealer appointments',
          value: 'dealerAppointment',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Rank',
          description: 'Dealer rank',
          value: 'dealerRank',
        },
        {
          align: 'center',
          sortable: true,
          text: 'KPI',
          description: 'KPI count',
          value: 'kpiCount',
        },
        {
          align: 'center',
          sortable: true,
          text: 'LEAD',
          description: 'LEAD certificates',
          value: 'lead',
        },
      ],
      headersAd: [
        {
          align: 'left',
          sortable: true,
          text: 'Area Dealer',
          description: 'Area dealer Id',
          value: 'dealer',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Qualified Tiers',
          description: 'Qualified tiers',
          value: 'qualifiedTiers',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Pp Grp Reg',
          description: 'Prepaid group registrations',
          value: 'prepaidGroupRegistration',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Pp Grp Act',
          description: 'Prepaid group activations',
          value: 'prepaidGroupActivation',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Blk Grp Act',
          description: 'Black group activations',
          value: 'blackGroupActivation',
        },
        {
          align: 'center',
          sortable: true,
          text: '4-tier Grp Act',
          description: '4-tier group activations',
          value: 'dealerGroupActivation',
        },
        {
          align: 'center',
          sortable: true,
          text: 'E-recharge',
          description: 'E-recharge sales',
          value: 'erechargeSales',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Appointments',
          description: 'New dealer appointments',
          value: 'dealerAppointment',
        },
      ],
      dealers: [
      ],
      loading: false,
      maxDate: '',
      menu: false,
      monthOf: null,
      noDataText: "There's nothing to display.",
      noResultText: 'No data that fits that criteria.',
      pagination: {
        descending: true,
        sortBy: 'dealer',
      },
      paginationAd: {
        descending: true,
        sortBy: 'dealer',
      },
      search: '',
      self: null,
      title: reportTitle,
      defaultAdSelection: 'All Area Dealers',
    }
  },
  computed: {
    adList: function () {
      let areaDealer = [this.defaultAdSelection, '- direct dealers -']

      this.areaDealers.forEach(item => {
        areaDealer = areaDealer.concat(item.dealer)
      })

      return areaDealer
    },
    filteredDealers: function () {
      if (this.currentAd === this.defaultAdSelection) {
        return this.dealers
      }

      return this.dealers.filter(dealer => {
        return this.currentAd === '- direct dealers -' ? security.me.dealerId() === dealer.leadDealer : this.currentAd === dealer.leadDealer
      })
    },
  },
  watch: {
    monthOf: function (val) {
      this.getPerformance(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
    this.currentAd = this.defaultAdSelection
  },
  methods: {
    exportDealerPerformance: function () {
      const config = {
        filename: security.me.dealerId() + '-DealerPerformance-' + this.monthOf,
        sheet: {
          data: [],
        },
      }

      config.sheet.data.push(this.dealerPerformanceHeader)
      this.dealers.forEach(item => {
        const subs = [
          {
            value: item.msisdn,
            type: 'string',
          },
          {
            value: item.name,
            type: 'string',
          },
          {
            value: item.subscriptionDate,
            type: 'string',
          },
          {
            value: item.plan,
            type: 'string',
          },
          {
            value: item.dealer,
            type: 'string',
          },
          {
            value: item.dealershipDate,
            type: 'string',
          },
          {
            value: item.dealerRank,
            type: 'string',
          },
          {
            value: item.tier,
            type: 'number',
          },
          {
            value: item.dealerCount,
            type: 'number',
          },
          {
            value: item.subscriberCount,
            type: 'number',
          },
          {
            value: item.prepaidRegistration,
            type: 'number',
          },
          {
            value: item.prepaidActivation,
            type: 'number',
          },
          {
            value: item.blackActivation,
            type: 'number',
          },
          {
            value: item.groupActivation,
            type: 'number',
          },
          {
            value: item.erechargeSales,
            type: 'number',
          },
          {
            value: item.dealerAppointment,
            type: 'number',
          },
          {
            value: item.kpiCount,
            type: 'number',
          },
          {
            value: item.lead,
            type: 'number',
          },
          {
            value: item.intro,
            type: 'string',
          },
          {
            value: item.leadDealer,
            type: 'string',
          },
        ]

        this.areaDealers.length && subs.push({
          value: item.leadDealer === security.me.dealerId() ? '-direct-' : item.leadDealer,
          type: 'string',
        })

        config.sheet.data.push(subs)
      })

      zipcelx(config)
    },
    allowedMonths: function (month) {
      const ym = month.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2018, 1, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    getPerformance (month) {
      this.loading = true
      const params = createGetParams({
        month: month,
      })
      this.loading = true
      this.areaDealers = []
      this.dealers = []
      this.adQualifiedTiers = 0
      this.adPrepaidGroupRegistration = 0
      this.adPrepaidGroupActivation = 0
      this.adBlackGroupActivation = 0
      this.adDealerGroupActivation = 0
      this.adErechargeSales = 0
      this.adDealerAppointment = 0
      this.totalBlackActivation = 0
      this.totalGroupActivation = 0
      this.totalErechargeSales = 0.00
      this.totalDealerAppointment = 0
      this.totalPrepaidRegistration = 0
      this.totalPrepaidActivation = 0
      this.$rest.get('getPerformance.php', params)
        .then(function (response) {
          this.self = response.data.self
          this.dealers = response.data.dealer
          this.dealers.forEach(item => {
            this.totalBlackActivation += item.blackActivation
            this.totalGroupActivation += item.groupActivation
            this.totalErechargeSales += item.erechargeSales * 1
            this.totalDealerAppointment += item.dealerAppointment
            this.totalPrepaidRegistration += item.prepaidRegistration
            this.totalPrepaidActivation += item.prepaidActivation
          })
          if (response.data.areaDealer) {
            this.dealerPerformanceHeader.push({
              value: 'Area Dealer',
              type: 'string',
            })
            this.areaDealers = response.data.areaDealer
            this.areaDealers.forEach(item => {
              this.adQualifiedTiers += item.qualifiedTiers
              this.adPrepaidGroupRegistration += item.prepaidGroupRegistration
              this.adPrepaidGroupActivation += item.prepaidGroupActivation
              this.adBlackGroupActivation += item.blackGroupActivation
              this.adDealerGroupActivation += item.dealerGroupActivation
              this.adErechargeSales += item.erechargeSales * 1
              this.adDealerAppointment += item.dealerAppointment
            })
          }
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
  },
}
</script>
